import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import VueI18n from 'vue-i18n'
import ru from 'vuetify/lib/locale/ru'
import DadataSuggestions from 'vue-dadata-suggestions'
import VueDadata from 'vue-dadata'
import YmapPlugin from 'vue-yandex-maps'
import VueTheMask from 'vue-the-mask'

Vue.use(Vuetify)
Vue.use(VueI18n)
Vue.use(DadataSuggestions, {
  token: 'ffb57c11e55183d9837080cd6d06211b360f0663',
  type: 'ADDRESS'
})
Vue.use(VueDadata)
Vue.use(VueTheMask)

const settings = {
  apiKey: 'c0cd85c5-7a6c-4a78-b129-07e77ddaa7ea',
  version: '2.1',
  controls: ['largeMapDefaultSet']
}

Vue.use(YmapPlugin, settings)

export default new Vuetify({
  lang: {
    locales: { ru },
    current: 'ru'
  }
})

<template>
  <v-app>
    <v-main>
      <transition name="fade" :duration="this.$route.path==='/'  ? 0 : 400 ">
        <router-view/>
      </transition>
    </v-main>

    <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
      Доступно обновление
      <v-btn text @click="refreshApp">
        Обновить
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import update from './mixins/update'

export default {
  name: 'App',

  components: {
    //  NextButton
    //  HelloWorld
  },
  mixins: [update],

  data: () => ({
    //
  }),

  mounted () {
    const html = document.documentElement
    html.setAttribute('lang', 'ru')

    // counters
    if (process.env.NODE_ENV === 'production') {
      // vk
      var t = document.createElement('script')
      t.type = 'text/javascript'
      t.async = !0
      t.src = 'https://vk.com/js/api/openapi.js?169'
      t.onload = function () {
        window.VK.Retargeting.Init('VK-RTRG-938146-542Ek')
        window.VK.Retargeting.Hit()
      }
      document.head.appendChild(t)

      // vk pixel
      // const vk = document.createElement('img')
      // vk.src = 'https://vk.com/rtrg?p=VK-RTRG-938146-542Ek'

      // mail.ru
      var _tmr = window._tmr || (window._tmr = [])
      _tmr.push({ id: '3215892', type: 'pageView', start: (new Date()).getTime(), pid: 'USER_ID' });

      (function (d, w, id) {
        if (d.getElementById(id)) return
        var ts = d.createElement('script')
        ts.type = 'text/javascript'
        ts.async = true
        ts.id = id
        ts.src = 'https://top-fwz1.mail.ru/js/code.js'
        var f = function () {
          var s = d.getElementsByTagName('script')[0]
          s.parentNode.insertBefore(ts, s)
        }
        if (w.opera === '[object Opera]') {
          d.addEventListener('DOMContentLoaded', f, false)
        } else {
          f()
        }
      })(document, window, 'topmailru-code')

      // mail.ru pixel
      const px = document.createElement('img')
      px.src = 'https://top-fwz1.mail.ru/tracker?id=3215892;e=RG%3A/trg-pixel-11054989-1619513578117;_=' +
        Math.random().toString(36).substr(2);

      // Facebook Pixel Code
      (function (f, b, e, v) {
        if (f.fbq) return
        var n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        var t = b.createElement(e)
        t.async = !0
        t.src = v
        var s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')

      window.fbq('init', '457685215509269')
      window.fbq('track', 'PageView')
    }
  },

  created () {}
}
</script>
<style>
@media (max-width: 400px) {
  .col {
    padding: 6px !important;
  }
}

@media (max-width: 400px) {
  /*.button-block {*/
  /*  padding-top: 12px;*/
  /*  margin: 0 16px;*/
  /*}*/

  /*.button-block div.text-center:nth-child(even) {*/
  /*  padding-right: 6px;*/
  /*  padding-left: 4px;*/
  /*  padding-bottom: 6px;*/
  /*  padding-top: 6px;*/
  /*  !*background: red;*!*/
  /*}*/

  /*.button-block div.text-center:nth-child(odd) {*/
  /*  padding-right: 4px;*/
  /*  padding-left: 6px;*/
  /*  padding-bottom: 6px;*/
  /*  padding-top: 6px;*/
  /*  !*background: greenyellow;*!*/

  /*}*/
}

.mar-top-60 {
  margin-top: 60px;
}

.mar-bot-80 {
  margin-bottom: 80px;
}

.v-btn {
  font-size: 1rem !important;
}

.v-btn.next-step, .v-btn.prev-step {
  height: 44px !important;
  box-shadow: none !important;
  text-transform: none;
}

.v-btn.call-me {
  color: #2688EB !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.next-step.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #9e9e9e !important;
  color: rgba(255, 255, 255, 0.8) !important;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.75s ease-out;
}
.slide-enter-to {
  position: absolute;
  right: 0;
}
.slide-enter-from {
  position: absolute;
  right: -100%;
}
.slide-leave-to {
  position: absolute;
  left: -100%;
}
.slide-leave-from {
  position: absolute;
  left: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.slide-leave-from, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.v-dialog.dialog-result {
  margin-right: 32px !important;
  margin-left: 32px!important;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('../views/Main.vue')
  },
  {
    path: '/order/:step',
    name: 'Order',
    component: () => import('../views/Order.vue'),
    props: true
  },
  {
    path: '/result/:token/:date',
    name: 'Result',
    component: () => import('../views/Result.vue'),
    props: true
  },
  {
    path: '/history',
    name: 'History',
    component: () => import('../views/History.vue'),
    props: true
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/price',
    name: 'Price',
    component: () => import('../views/Price.vue')
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import VueLocalStorage from 'vue-localstorage'
import VueYandexMetrika from 'vue-yandex-metrika'
// import colors from 'vuetify/lib/util/colors'

import VueFileAgent from 'vue-file-agent'
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css'

// const VueCookie = require('vue-cookie')
// Vue.use(VueCookie)
Vue.use(VueLocalStorage, {
  name: 'ls',
  bind: true
})

Vue.use(VueFileAgent)
Vue.use(VueFileAgentStyles)

Vue.use(VueYandexMetrika, {
  id: 73500226,
  router: router,
  env: process.env.NODE_ENV,
  options: {
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
    webvisor: true
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App),
  ls: {
    currentOrder: {
      type: Object,
      default: {}
    },
    ordersHistory: {
      type: Object,
      default: []
    },
    addresses: {
      type: Object,
      default: []
    },
    user: {
      type: Object,
      default: {
        name: null,
        phone: null
      }
    }
  }
}).$mount('#app')
